import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'DefaultLayout',
      component: () => import('@/layout/default.vue'),
      children: [
        {
          path: '/',
          name: 'Home',
          component: () => import('@/views/Home.vue')
        },
        {
          path: '/catalog',
          name: 'Catalog',
          component: () => import('@/views/Catalog.vue')
        },
        {
          path: '/reports',
          name: 'Reports',
          component: () => import('@/views/Reports.vue')
        },
        {
          path: '/reports/add',
          name: 'ReportsAdd',
          component: () => import('@/views/ReportsAdd.vue')
        },
        {
          path: '/deal/:id',
          name: 'Deal',
          component: () => import('@/views/Deal/index.vue')
        },
        {
          path: '/deal/:id/participant',
          name: 'Participants',
          component: () => import('@/views/Deal/Participants.vue')
        }
      ],

    },


  ]
})
