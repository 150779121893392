import Vue from 'vue'
import Vuex from 'vuex'
import Category from './module/Category'
import User from './module/User'
import Deal from './module/Deal'


Vue.use(Vuex)

const store = new Vuex.Store({
  namespaced: true,
  modules: {
    Category,
    User,
    Deal,
  }
});


export default store

