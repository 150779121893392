import ApiUser from '@/api/users';
export default {
    namespaced: true,

    state: {
        createForm: {},

    },

    getters: {
        createForm: (state) => state.createForm,
    },

    mutations: {
        SET_DATA_FORM: (state, payload) => {
            state.createForm = Object.assign(state.createForm, payload);
        },
    },
    actions: {
        setDateCreate({ commit }, params) {
            commit("SET_DATA_FORM", params)
        },
    }
};
