import API from '@/api';
export default {
	getSelfUser: () => {
		return API.get(`/users/self`);
	},
	getUser: (id) => {
		return API.get(`/users/${id}`);
	},
	sendMessage: (id) => {
		return API.post(`/users/${id}/message`);
	},
};
