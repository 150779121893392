import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import i18n from '@/i18n'
import OpenModal from "./helpers/openModal"

import { Locale } from 'vant';
import enUS from 'vant/es/locale/lang/en-US';

Locale.use('en-US', enUS);
import Vant from 'vant';
Vue.use(Vant);

Vue.prototype.$openModal = OpenModal;
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI)

import TextareaAutosize from 'vue-textarea-autosize'
Vue.use(TextareaAutosize)

import 'vant/lib/index.css';
import "@/assets/scss/style.scss";

export const eventBus = new Vue(); // creating an event bus.

import vClickOutside from 'v-click-outside'
Vue.use(vClickOutside)

import VueMeta from 'vue-meta'
Vue.use(VueMeta)

new Vue({
    el: "#app",
    router: router,
    store,
    i18n,
    render: h => h(App),
})


