import ApiUser from '@/api/users';
export default {
    namespaced: true,

    state: {
        selfUser: {},
        isSelfUserLoading: true
    },

    getters: {
        selfUser: (state) => state.selfUser,
        isSelfUserLoading: (state) => state.isSelfUserLoading,
    },

    mutations: {
        SET_SELF_USER: (state, payload) => {
            state.selfUser = payload;
        },
        SET_IS_SELF_USER_LOADING: (state, payload) => {
            state.isSelfUserLoading = payload;
        },
    },
    actions: {
        getSelfUser({ commit }) {
            commit("SET_IS_SELF_USER_LOADING", true)
            return ApiUser.getSelfUser().then((responce) => {
                if (Object.keys(responce.data).length != 0) {
                    // console.log(Object.keys(responce.data).length);
                    commit("SET_IS_SELF_USER_LOADING", false)
                    commit("SET_SELF_USER", responce.data)
                } else {

                }
                return responce.data
            });
        },
    }
};
