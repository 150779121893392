<template>
  <div>
    <router-view />
  </div>
</template>

<script>
export default {
  name: "App",
  data() {
    return {
      // colorScheme: window.Telegram.WebApp.colorScheme,
    };
  },
  computed: {},
  components: {},
  created() {
    window.Telegram.WebApp.expand();
  },
  mounted() {
    // document.body.classList.add(`${this.colorScheme}-scheme`);
  },
};
</script>

<style lang="scss">
@import "./assets/normalize.css";
</style>
