const config = function () {
  return {
    baseURL: 'https://vmeste.app',
    headers: {
      Accept: 'application/json',
      "Content-Type": "multipart/form-data",
    },
  };
};

export default config;
